import React from "react"
import { Link } from "gatsby"

const CustomBreadcrumb = ({ crumbs }) => {
  return (
    <div className="breadcrumb__container blog__breadcrumb--container">
        <div className="breadcrumb__container--box">
        {crumbs.map(crumb => {
          if (crumb.crumbLabel.includes("-")){
            var label = (crumb.crumbLabel.replace(/-/g, " "))
          } else {
            label = (crumb.crumbLabel)
          }
            return (
            <div className="breadcrumb" style={{ display: "inline" }} key={label}>
                <Link
                className={label}
                to={crumb.pathname}
                style={{ ...crumb.crumbStyle }}
                activeStyle={{ ...crumb.crumbActiveStyle }}
                >
                {label}
                </Link>
                {crumb.crumbSeparator || " "}
            </div>
            )
        })}
      </div>
    </div>
  )
}

export default CustomBreadcrumb
